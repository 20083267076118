import http from "./http.service";
import Promisable from "./promisable.service";
import { authActions } from "redux/slices/auth";
import { getAppDispatch } from "utils/dispatch.util";
import { bookingActions } from "./../redux/slices/booking";
import { MODAL, modalActions } from "redux/slices/modal";

const url = "/trip";

const BookingService = {
  verifyBooking: async (id: any) => {
    const [success, error]: any = await Promisable.asPromise(
      http.get(
        `/tripConfirmation?merchant_reference=${id}&is_verification=true&response_code=02000&service_command=VERIFICATION`
      )
    );
    return [success.data.data, error];
  },
  addBooking: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoading(true));
    http.setJWT();
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    dispatch?.(bookingActions.setLoading(false));
    return [success, error];
  },

  processPayment: async (id: any) => {
    http.acceptLanguage();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`/paymentProcess/${id}`)
    );
    return [success.data.data, error];
  },
  additoonalProcessPayment: async (id: any) => {
    http.acceptLanguage();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`/additionalPaymentProcess/${id}`)
    );
    return [success.data.data, error];
  },

  processApplePay: async (data: any) => {
    http.acceptLanguage();
    const config = { baseURL: http.getAPPLEPAYBaseUrl() };
    console.log("config", config);
    const [success, error]: any = await Promisable.asPromise(
      http.post(`/api/v2/user/web/apple-pay/merchant-validation`, data, config)
    );
    return [success.data.data, error];
  },
  processApplePayAuth: async (id: string, data: any) => {
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`/apple-payment-processing/${id}`, data)
    );
    return [success.data.data, error];
  },

  makePayment: async (
    id: any,
    card_number: any,
    holder: any,
    expiry_month: any,
    expiry_year: any,
    cvv: any,
    brand: any
  ) => {
    http.acceptLanguage();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`/tripConfirmation`, {
        card_number,
        holder,
        expiry_month,
        expiry_year,
        cvv,
        brand,
        ref_id: id,
        pType: true,
      })
    );
    return [success.data.data, error];
  },

  updateBooking: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update/${id}`, data)
    );
    if (success) {
      const { trip } = success.data.data;

      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.updateBooking(trip));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  confirmBooking: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoading(true));
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/confirmtrip`, data)
    );

    dispatch?.(bookingActions.setLoading(false));
    return [success, error];
  },

  cancelBooking: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`)
    );

    if (success) {
      const { trip, message, is_refund } = success.data.data;

      dispatch?.(modalActions.closeModal());

      if (trip) dispatch?.(bookingActions.updateBooking(trip));

      if (!is_refund)
        dispatch?.(
          modalActions.openModal({
            width: "500px",
            type: MODAL.CONFIRMATION_FORM,
            data: {
              id,
              message,
              type: MODAL.CONFIRM_CANCEL_BOOKING,
              heading: "Confirm Booking Cancellation",
            },
          })
        );
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  confirmCancelBooking: async (id: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));
    http.acceptLanguage();
    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/confirmcancel/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;

      if (trip) dispatch?.(bookingActions.updateBooking(trip));

      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  getAllBookings: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoading(true));

    http.setJWT();
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}`)
    );

    if (success) {
      const { trips } = success.data.data;
      dispatch?.(bookingActions.setBookings(trips));
    }

    dispatch?.(bookingActions.setLoading(false));

    return [success, error];
  },

  getBookingPrices: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoading(true));

    const config = { baseURL: http.getGuestBaseUrl() };
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/getprices`, data, config)
    );

    dispatch?.(bookingActions.setLoading(false));
    return [success, error];
  },

  tripRating: async (data: any) => {
    const dispatch = getAppDispatch();

    http.setJWT();
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/rating`, data)
    );

    if (success) dispatch?.(authActions.setUserTripRating(true));

    return [success, error];
  },

  confirmTip: async (data: any) => {
    http.setJWT();
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/confirmTip`, data)
    );

    return [success, error];
  },

  reportProblem: async (id: string, data: any) => {
    http.setJWT();
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/report/${id}`, data)
    );

    // if (success) {
    // 	const { user } = success.data.data;
    // 	dispatch?.(authActions.setUser(user));
    // }

    return [success, error];
  },

  requestHistory: async (data: any) => {
    let payload: any = {};
    const response = await fetch("https://geolocation-db.com/json/");
    const res = await response.json();
    payload.userID = data.user?._id;
    payload.pickup = `${data.name} ${data.formatted_address}`;
    payload.city = data.city;
    payload.lat = data.geometry.location.lat;
    payload.lng = data.geometry.location.lng;
    payload.iP = res?.IPv4;
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.post("/request-history", payload)
    );
    return [success, error];
  },

  bookingHistory: async (data: any) => {
    let payload = { ...data };
    let prices: any = [];
    data?.classes &&
      data?.classes.forEach((element: any) => {
        prices.push({
          class: element.class_name,
          price: element.price_details.netto,
        });
      });
    payload.prices = prices;
    delete payload.classes;
    const response = await fetch("https://geolocation-db.com/json/");
    const res = await response.json();
    payload.pickup = data.pickup;
    payload.destination = data?.destination ? data?.destination : data?.hours;
    payload.iP = res.IPv4;
    http.acceptLanguage();

    const [success, error]: any = await Promisable.asPromise(
      http.post("/request-history", payload)
    );
    return [success, error];
  },
};

export default BookingService;
